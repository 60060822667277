import "./App.css";
import logoText from "./Name.svg";
import logo from "./GanjaRooGif.gif";
import twitterLogo from "./twitter-logo.svg";
import discordLogo from "./discord-logo.svg";

function App() {
  return (
    <div className="App">
      <img src={logoText} id="ganjaroo-logo" alt="Ganjaroo text logo" />
      <div className="logo">
        <img src={logo} alt="Ganjaroo Logo" />
      </div>
      <div className="main-text">
        <p>
          “The illegality of cannabis is outrageous, an impediment to full
          utilization of a drug which helps produce the serenity and insight,
          sensitivity and fellowship so desperately needed in this increasingly
          mad and dangerous world.”
        </p>
        <p className="citation-by">― Carl Sagan</p>
      </div>

      <div className="media-logos">
        <div className="media-wrapper">
          <div className="media-logo">
            <a
              href="https://www.twitter.com/GanjaRooGang"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterLogo} alt="Twitter Logo" />
            </a>
          </div>
        </div>
        <div className="media-wrapper"></div>
        <div className="media-logo">
          <a
            href="https://discord.gg/gAMrPtjr"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discordLogo} alt="Discord Logo" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
